import { Component, OnInit, Input } from '@angular/core';
import { StudentMasterService } from '../../Masters/student-master/student-master.service';
import { DepartmentMasterService } from '../../Masters/department-master/department-master.service';
import { SectionMasterService } from '../../Masters/section-master/section-master.service';
import { ClassMasterService } from '../../Masters/class-master/class-master.service';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { DepartmentMasterPojo } from 'src/app/pojo/department-master.pojo';
import { StudentMasterPojo } from 'src/app/pojo/student-master.pojo';
import { SectionMasterPojo } from 'src/app/pojo/section-master.pojo';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { ReportService } from '../../Reports/report-service';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { FeeAllocationMasterService } from '../fee-allocation/fee-allocation.service';
import { FeeAllocationReportByTermWisePojo, FeeCollectionPojo, FeeCollectionDetailsPojo } from 'src/app/pojo/fee-collection.pojo';
import { ReceiptPojo } from 'src/app/pojo/receipt.pojo';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { FeeCollectionService } from './fee-collection.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ReceiptHeadService } from '../../Accounts/receipt-head/receipt-head.service';
import { ReceiptHeadPojo } from 'src/app/pojo/receipt-head.pojo';

import { TermMasterService } from '../../Masters/term/term.service';
import { TermPojo } from 'src/app/pojo/term-pojo';
import { CommonReceiptComponent } from '../../CommonReceipt';
import * as converter from 'number-to-words';
import { FeeCollectionReceiptPojo, FeeCollectionReceiptDetailsPojo } from 'src/app/pojo/temporary-fees-collection-receipt.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-fee-collection',
  templateUrl: './fee-collection.component.html',
  styleUrls: ['./fee-collection.component.scss']
})
export class FeeCollectionComponent implements OnInit {
  model: NgbDateStruct;
   date: { year: number, month: number };
   form: boolean = false;
   
   addEntryDate: NgbDateStruct;
   
  classArr: ClassMasterPojo[] = [];
  departmentArr: DepartmentMasterPojo[] = [];
  studentMasterArr: StudentMasterPojo[] = [];
  sectionArr: SectionMasterPojo[] = [];
  academic:AcademicYearMasterPojo[] =[];
  standardArr: AcademicStandardDetailsPojo[] = [];
  academicYr;
  departmentId;
  classid;
  sectionid;
  studentid;
  isLoading:boolean = false;
  feeCollectionArr:FeeAllocationReportByTermWisePojo[] =[];
  addReceipt = new ReceiptPojo();
  public acc: number[] = [2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23];
  public paidacc: number[] = [1, 2];
  picksledgersByAccountGroup: LedgerPojo[] = [];
  pickspaidledgersByAccountGroup: LedgerPojo[] = [];
  loggedUser = new MUserMasterPojo();
  standTermArr: StandardTermPojo[] = [];
  @Input() d:string = 'Haiiiii';
  ob: boolean[] = [];
  moveFlag:boolean=false;
  public CData: number;
  admissionNum;
  tempPushArr:TempFeeDetArrayPojo[] =[];
  tempDiscount;
  tempTotalAmnt;
  tempAmount;
  tempDiscounted;
  toggle: boolean[] = [];
  amnt;
  finalTotalAmnt:number = 0;
  finalDiscountTotal:number =0;
  finalPaidAmount:number =0;
  feePojo = new FeeCollectionPojo();
  finalClassId;
  finalSectionId;
  selectedReceiptId;
  finalStudentId;
  selectedLedgerId;
  currentDate;
  receiptHeadArr:ReceiptHeadPojo[] = [];
  temp=new FeeCollectionPojo();
  overAllOriginalTotal:number=0;
  overAllAlreadyPaidTotal:number =0;
  studentMasterAllArr:StudentMasterPojo[]=[];
  termArr: TermPojo[] = [];
  accountMasterArr:AccountMasterPojo[]=[];
  constructor( private accountMasterService:AccountMasterService,private feeTypemasterserivce: TermMasterService,private receiptheadService:ReceiptHeadService,private feeCollectionService:FeeCollectionService,private feeStructuremasterserivce: StandardStructureMasterService,private ledgercreationService: LedgercreationService,private feeAloocationService:FeeAllocationMasterService,private reportService:ReportService ,private studentMasterService: StudentMasterService,private settingService:AcademicSettingService, private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService, private classMasterService: ClassMasterService ) { }
   ngOnInit() {
    
    $( '#addAfterClick' ).hide();
    this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
     this.getSectionMasterAll();
    this.getClassMasterAll();
    this.getDepartmentAll();
     this.academicSettingAll();
     this.getLedgerByAccountGroup( this.acc);
     this.getStandrdTermAll(); 
     this.getAllReceiptHead(); 
     this.getStudentMasterAll();
     this.getTermAll();
     this.accountMasterAll();
     this.currentDate = moment().format('DD-MM-YYYY');
    // CommonReceiptComponent.FeeCollectionReceipt(this.temp);
  }
  accountMasterAll() {
    this.accountMasterService.getAllAccountMaster().subscribe(response => {
      this.accountMasterArr = response;
     
    }, error => {
      bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
    });
  }
  getAccountNameById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).accName;
    } else {
      return id;
    }
  }
  getAccountPlaceById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).place;
    } else {
      return id;
    }
  } getAccountDistrictById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).district;
    } else {
      return id;
    }
  }
  getDatafromChild(id){
    this.admissionNum = id;
    $( '#addModal' ).modal( 'hide' );
    this.getFeeAllocationById();
  }
  getTermAll() {
     this.feeTypemasterserivce.getAllTerm(this.loggedUser.accId).subscribe( response => {
        this.termArr = response;
        }, error => {
        bootbox.alert( { message: 'Get FeeType All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
}
  getFeeAllocationById(){
    this.feeCollectionArr =[];
    this.isLoading = true;  
    this.reset();
    this.feeAloocationService.getAcademicdetByYrIdandAdmissioNo(this.academicYr,this.admissionNum,this.loggedUser.accId).subscribe( response => {
      this.feeCollectionArr= response;
      for(let i=0;i<this.feeCollectionArr.length;i++){
        for(let j=0;j<this.feeCollectionArr[i].flist.length;j++){
        if(i == 0){    
          this.finalClassId = this.feeCollectionArr[i].flist[j].classId;
          this.finalSectionId = this.feeCollectionArr[i].flist[j].sectionId;
          this.finalStudentId = this.feeCollectionArr[i].flist[j].studentId;
        }
      }
      }
         this.isLoading = false;
       }, error => {
      bootbox.alert( { message: 'Get Fee Collection Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      this.isLoading = false;   
    } );
  }
  getLedgerByAccountGroup( Acc ) {
    this.ledgercreationService.getLedgerByAccountGroup( this.acc,this.loggedUser.accId ).subscribe( log => {
        this.picksledgersByAccountGroup = log;
    } );
    this.ledgercreationService.getLedgerByType('PAID',this.loggedUser.accId).subscribe( log => {
        this.pickspaidledgersByAccountGroup = log;
    } );  
}
  getSectionMasterAll() {
    this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
        this.sectionArr = response;
        }, error => {
        bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
}
getClassMasterAll() {
  this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
      this.classArr = response;
       }, error => {
      bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      } );
}
getDepartmentAll() {
  this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
      this.departmentArr = run;
  }, error => {
      bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      } );
}
getStudentById() {
  this.studentMasterArr =[];
  this.reportService.getClassWiseReportById( this.departmentId, this.classid, this.sectionid,this.academicYr,this.loggedUser.accId ).subscribe( response => {
    this.standardArr = response;
    for(let i=0;i<this.standardArr.length;i++){
        this.studentMasterArr.push(this.standardArr[i].student);
    }
    }, error => {
    bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
   } );
}
getStandrdTermAll() {
  this.isLoading = true;
  this.feeStructuremasterserivce.getAllStandardTerm(this.loggedUser.accId).subscribe( response => {
      this.standTermArr = response;
      this.isLoading = false;
  }, error => {
      bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      this.isLoading = false;
  } );
}
getStudentMasterAll(){
  this.studentMasterArr = [];
     this.studentMasterService.getStudentAll(this.loggedUser.accId).subscribe( response => {
  this.studentMasterAllArr = response;
  }, error => {
      bootbox.alert( { message: 'Get Student Master All Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
       } );
}
getAllReceiptHead() {
  this.isLoading = true;
  this.receiptheadService.getReceiptHeadAll(this.loggedUser.accId).subscribe( rs => {
      this.receiptHeadArr = rs;
      this.isLoading = false;

  }, error => {
      bootbox.alert( 'Error' + error );
      this.isLoading = false;
  } );
}
academicSettingAll(){
  this.academic = [];
  this.settingService.getAcademicYrAll().subscribe( response => {
      this.academic = response;
      for(let i=0;i<this.academic.length;i++){
          if(this.academic[i].academicFlag == 1){
                this.academicYr = this.academic[i].academicYearId;
                }
      }
      
        }, error => {
      bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
       } );
 }
 getAcademicYrById( id ) {
  if ( this.academic.find( r => r.academicYearId == id ) ) {
      return this.academic.find( r => r.academicYearId == id ).academicYear;
  } else {
      return id;
  }
}
getClassNameById( id ) {
  if ( this.classArr.find( r => r.classId == id ) ) {
      return this.classArr.find( r => r.classId == id ).className;
  } else {
      return id;
  }
}
getSectionNameById( id ) {
  if ( this.sectionArr.find( r => r.sectionId == id ) ) {
      return this.sectionArr.find( r => r.sectionId == id ). sectionName;
  } else {
      return id;
  }
}
getStudentNameById( id ) {
  if ( this.studentMasterAllArr.find( r => r.studentId == id ) ) {
      return this.studentMasterAllArr.find( r => r.studentId == id ).name;
  } else {
      return id;
  }
}
getStandardTermById( id ) {
  if ( this.standTermArr.find( r => r.standardTermId == id ) ) {
      return this.standTermArr.find( r => r.standardTermId == id ).standardTerm;
  } else {
      return id;
  }
}
getTermById( id ) {
  if ( this.termArr.find( r => r.termId == id ) ) {
      return this.termArr.find( r => r.termId == id ).termName;
  } else {
      return id;
  }
}
closeModal(){
  $( '#addModal' ).modal( 'hide' );
}

editData( i ): void {
  this.tempDiscount =0;
  this.tempTotalAmnt =0;
  this.tempAmount =0;
  this.tempDiscounted =0;
  //this.amnt =0;
  this.tempDiscount = this.tempPushArr[i].discount;
  this.tempTotalAmnt = this.tempPushArr[i].paidAmount;
  this.tempAmount =  this.tempPushArr[i].amount;
  this.tempDiscounted =  this.tempPushArr[i].discountedAmnt;
 // this.amnt = this.tempPushArr[i].amount;
  this.toggle[i] = true;
}
commonCalc(){
  this.finalTotalAmnt =0;
  this.finalDiscountTotal =0;
  this.finalPaidAmount = 0;
  this.overAllOriginalTotal=0;
      this.overAllAlreadyPaidTotal=0
      for(let i=0;i<this.tempPushArr.length;i++){  
        // this.amnt = this.tempPushArr[i].amount + this.amnt;
         this.finalTotalAmnt = this.tempPushArr[i].discountedAmnt+this.finalTotalAmnt;
         this.finalDiscountTotal = this.tempPushArr[i].discount +this.finalDiscountTotal;
         this.finalPaidAmount =  this.tempPushArr[i].paidAmount + this.finalPaidAmount;
         this.overAllOriginalTotal = this.tempPushArr[i].originalAmount+ this.overAllOriginalTotal;
         this.overAllAlreadyPaidTotal = this.tempPushArr[i].alreadyPaidAmnt+ this.overAllAlreadyPaidTotal;
         
       }
}
okData( i, discount,amnt ): void {
  this.tempPushArr[i].discount = Number( discount );
  this.tempPushArr[i].amount = Number( amnt );
      // let disc = (amnt*discount)/100;
  this.tempPushArr[i].discountedAmnt = Number(amnt) -discount;
  this.tempPushArr[i].balance = this.tempPushArr[i].totalAmount - this.tempPushArr[i].amount;
  this.tempPushArr[i].paidAmount =this.tempPushArr[i].discountedAmnt;
   this.commonCalc();
    }
okDataSubmit( i ) {
  if(this.tempPushArr[i].balance >=0){
      this.toggle[i] = false;
      this.commonCalc();
    }else{
      alert('You cannot pay higher than your original amount..');
    }
  }
cancelData( i ) {
      this.toggle[i] = false;
      ( <HTMLInputElement>document.getElementById( 'discountAmt'+i ) ).value = this.tempDiscount;
      ( <HTMLInputElement>document.getElementById( 'amnt'+i ) ).value = this.tempAmount;
      this.tempPushArr[i].discountedAmnt = this.tempDiscounted;
      this.tempPushArr[i].discount = this.tempDiscount;
      this.tempPushArr[i].amount = this.tempAmount;
       this.tempPushArr[i].balance = this.tempPushArr[i].totalAmount - this.tempPushArr[i].amount;
      this.tempPushArr[i].paidAmount =  this.tempPushArr[i].discountedAmnt - this.tempPushArr[i].discount;
      this.commonCalc();
    }
  deleteRow( i ): void {
    this.tempPushArr.splice(i,1);
    this.commonCalc();
  }
  noEnter( e ) {
    return !( window.event && e.keyCode == 13 );
}
selectTermAll( variable:any,i) {
  if ( variable.checked ) {
      this.confirmSelectTermAll(i);
  } else {
      this.deSelectTermAll(i);
  }
}
confirmSelectTermAll(i){
    for ( let j = 0; j < this.feeCollectionArr[i].flist.length; j++ ){
    let d = this.tempPushArr.findIndex( r => r.standardTermId == (  this.feeCollectionArr[i].flist[j].standardTermId ) );
    if ( d < 0 ) {
        let d: TempFeeDetArrayPojo = new TempFeeDetArrayPojo();
        d.termId = this.feeCollectionArr[i].termId;
        d.termName = this.feeCollectionArr[i].termName;
        d.standardTermId = this.feeCollectionArr[i].flist[j].standardTermId;
        d.feeAllocationId = this.feeCollectionArr[i].flist[j].feeAllocationId;
        d.amount = this.feeCollectionArr[i].flist[j].balance;
        d.discount = this.feeCollectionArr[i].flist[j].discountAmount;
        d.discountedAmnt = this.feeCollectionArr[i].flist[j].balance;
        d.totalAmount =  this.feeCollectionArr[i].flist[j].balance;
        d.paidAmount =  this.feeCollectionArr[i].flist[j].balance;
        d.originalAmount = this.feeCollectionArr[i].flist[j].totalAmount;
        d.alreadyPaidAmnt = this.feeCollectionArr[i].flist[j].paidAmount;
        d.studentId = this.feeCollectionArr[i].flist[j].studentId;
           d.balance = 0;
        this.tempPushArr.push( d );
        this.commonCalc();
       }
  }

}
deSelectTermAll(i) {
   for ( let j = 0; j < this.feeCollectionArr[i].flist.length; j++) {
    let d = this.tempPushArr.findIndex( r => r.standardTermId == ( this.feeCollectionArr[i].flist[j].standardTermId ) );
       if ( d >= 0 ) {
          this.tempPushArr.splice( d, 1 );
          this.commonCalc();
          }
  }
}
isSelectedTermAll(i) {
  
  for ( let j = 0; j < this.feeCollectionArr[i].flist.length; j++ ) {
      if ( !this.tempPushArr.find( r => r.standardTermId == ( this.feeCollectionArr[i].flist[j].standardTermId ) ) ) {
          return false;
      }
  }
  return true;
}

isSelectedTerm( item ) {
  if ( this.tempPushArr.find( r => r.standardTermId == ( item.standardTermId ) ) ) {
      return true;
  }
  return false;
}
selectTerm( variable, arg:FeeCollectionDetailsPojo,i ) {
   if ( variable.checked ) {
      let d: TempFeeDetArrayPojo = new TempFeeDetArrayPojo();
       d.termId = this.feeCollectionArr[i].termId;
      d.termName = this.feeCollectionArr[i].termName;
      d.standardTermId = arg.standardTermId;
      d.feeAllocationId = arg.feeAllocationId;
      d.amount = arg.balance;
      d.discount = arg.discountAmount;
      d.discountedAmnt = arg.balance;
      d.totalAmount = arg.balance;
      d.paidAmount = arg.balance;
      d.originalAmount = arg.originalAmount;
       d.alreadyPaidAmnt = arg.paidAmount;
        d.balance = 0;
      this.tempPushArr.push( d );
      this.commonCalc();
  } else {
      let d = this.tempPushArr.findIndex( r => r.standardTermId == ( arg.standardTermId ) );
      if ( d >= 0 ) {
          this.tempPushArr.splice( d, 1 );
          this.commonCalc();
  }
}
}
openModal(){
  if(this.selectedLedgerId == 2){
  $( '#instrumentModal' ).modal( 'show' );  
  }
}
saveInst(){
  $( '#instrumentModal' ).modal( 'hide' );  
  
}
submit() {
  if(this.finalPaidAmount>0){
  ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
  $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
  //this.isLoading = true;
  this.feePojo.overAllBalanceTotal=0;
  this.feePojo.collectionTime = moment().format('hh:mm A');
 // this.feePojo.collectionDate = moment( this.addEntryDate.year + '-' + this.addEntryDate.month + '-' + this.addEntryDate.day, 'YYYY-MM-DD' ).toDate();
 this.feePojo.collectionDate = moment().toDate();
 this.feePojo.overAllOriginalTotal = this.overAllOriginalTotal;
 this.feePojo.overAllPaidTotal = this.finalPaidAmount;
  this.feePojo.overAllDiscountTotal = this.finalDiscountTotal;
   this.feePojo.overAllAlreadyPaidTotal = this.overAllAlreadyPaidTotal;
  //this.feePojo.overAllBalanceTotal = this.feePojo.overAllOriginalTotal - (this.feePojo.overAllPaidTotal+ this.feePojo.overAllDiscountTotal)+ this.feePojo.overAllAlreadyPaidTotal;
  this.feePojo.accId = this.loggedUser.accId;
  this.feePojo.admissionNo = this.admissionNum;
  this.feePojo.academicYearId = this.academicYr;
  this.feePojo.userId = this.loggedUser.userId;
  this.feePojo.classId = this.finalClassId;
  this.feePojo.sectionId = this.finalSectionId;
  //this.feePojo.receiptId = this.selectedReceiptId;
  this.feePojo.paidLedgerId = this.selectedLedgerId;
  for(let i=0;i<this.tempPushArr.length;i++){
     let d = new FeeCollectionDetailsPojo();
    d.termId = this.tempPushArr[i].termId;
    d.standardTermId =this.tempPushArr[i].standardTermId;
    d.paidAmount = this.tempPushArr[i].amount;
    d.originalAmount = this.tempPushArr[i].originalAmount;
    d.discountAmount = this.tempPushArr[i].discount;
    //d.discountAmount = (this.tempPushArr[i].amount*d.discountPercentage)/100;
    d.finalPaidAmount =  d.paidAmount - d.discountAmount;
    d.balance = this.tempPushArr[i].balance;
    d.feeAllocationId = this.tempPushArr[i].feeAllocationId;
    d.alreadyPaidAmount = this.tempPushArr[i].alreadyPaidAmnt;
    this.feePojo.overAllBalanceTotal = this.tempPushArr[i].balance+this.feePojo.overAllBalanceTotal;
    this.feePojo.studentId = this.tempPushArr[i].studentId;
    if(this.tempPushArr[i].amount>0){
      this.feePojo.flist.push(d);
    }
    
  }
  if(this.feePojo.paidLedgerId == 2){
    this.feePojo.instrumentDate = ( <HTMLInputElement>document.getElementById( 'addInstrumenEntrytDate' ) ).value;
  }else{
this.feePojo.instrumentType ="";
this.feePojo.instrumentNo="";
this.feePojo.instrumentDetails="";
this.feePojo.instrumentDate=null;
  }
  this.feeCollectionService.addFeeCollection(this.feePojo).subscribe( response => {
        bootbox.confirm('Successfully submitted !\n Do you want to send SMS to this student?', ( result ) => {
          let d= new FeeCollectionPojo();
          d.flist =[];
          let e= new FeeCollectionReceiptPojo();
          e.detList =[];
          d = response;
           
        if ( result ) {
          this.feeCollectionService.sendSmstoFeeCollection(this.feePojo).subscribe( response => {
            if(response ==101){
              bootbox.alert( { message: 'SMS Status for this type is Inactive!Please change the Status to Active..', className: "text-danger h5", closeButton: false } );
              let d= new FeeCollectionPojo();
          d.flist =[];
          let e= new FeeCollectionReceiptPojo();
          e.detList =[];
          d = response;
           
    e.academicYear = this.getAcademicYrById(d.academicYearId);
    e.admissionNo = d.admissionNo;
    e.className = this.getClassNameById(d.classId);
    e.sectionName = this.getSectionNameById(d.sectionId);
    e.studentName = this.getStudentNameById(d.studentId);
    e.receiptNo = d.receiptNo;
    e.overAllTotal = d.overAllOriginalTotal;
    e.overAllPaid = d.overAllPaidTotal+d.overAllDiscountTotal;
    e.overAllDiscount = d.overAllDiscountTotal;
    e.overAllAlreadyPaid = d.overAllAlreadyPaidTotal;
    e.overAllBalance = d.overAllBalanceTotal;
    e.currentDate = d.collectionDate;
    e.schoolName=this.getAccountNameById(this.loggedUser.accId);
    e.placeName=this.getAccountPlaceById(this.loggedUser.accId);
     for(let i=0;i<d.flist.length;i++){
       let g = new FeeCollectionReceiptDetailsPojo();
      g.termName = this.getTermById(d.flist[i].termId);
      g.standardTermName = this.getStandardTermById(d.flist[i].standardTermId);
      g.paidAmount = d.flist[i].paidAmount;
      g.originalAmount = d.flist[i].originalAmount;
      e.detList.push(g);

    }
    CommonReceiptComponent.FeeCollectionReceipt(e);
            }else{
              bootbox.alert( { message: 'Successfully sent SMS to this student..', className: "text-success h5", closeButton: false } );
             
        e.academicYear = this.getAcademicYrById(d.academicYearId);
        e.admissionNo = d.admissionNo;
        e.className = this.getClassNameById(d.classId);
        e.sectionName = this.getSectionNameById(d.sectionId);
        e.studentName = this.getStudentNameById(d.studentId);
        e.receiptNo = d.receiptNo;
        e.overAllTotal = d.overAllOriginalTotal;
        e.overAllPaid = d.overAllPaidTotal+d.overAllDiscountTotal;
        e.overAllDiscount = d.overAllDiscountTotal;
        e.overAllAlreadyPaid = d.overAllAlreadyPaidTotal;
        e.overAllBalance = d.overAllBalanceTotal;
        e.currentDate = d.collectionDate;
        e.schoolName=this.getAccountNameById(this.loggedUser.accId);
        e.placeName=this.getAccountPlaceById(this.loggedUser.accId);
        for(let i=0;i<d.flist.length;i++){
           let g = new FeeCollectionReceiptDetailsPojo();
          g.termName = this.getTermById(d.flist[i].termId);
          g.standardTermName = this.getStandardTermById(d.flist[i].standardTermId);
          g.paidAmount = d.flist[i].paidAmount;
          g.originalAmount = d.flist[i].originalAmount;
          e.detList.push(g);
    
        }
        CommonReceiptComponent.FeeCollectionReceipt(e);
         // bootbox.alert( { message: 'Successfully Added', className: 'text-success h5', closeButton: false } );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
           $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
          // this.isLoading = false;
          this.reset();
          this.admissionNum = "";  
            }
          }, error => {
            bootbox.alert( { message: 'SMS Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            let d= new FeeCollectionPojo();
          d.flist =[];
          let e= new FeeCollectionReceiptPojo();
          e.detList =[];
          d = response;
           
    e.academicYear = this.getAcademicYrById(d.academicYearId);
    e.admissionNo = d.admissionNo;
    e.className = this.getClassNameById(d.classId);
    e.sectionName = this.getSectionNameById(d.sectionId);
    e.studentName = this.getStudentNameById(d.studentId);
    e.receiptNo = d.receiptNo;
    e.overAllTotal = d.overAllOriginalTotal;
    e.overAllPaid = d.overAllPaidTotal+d.overAllDiscountTotal;
    e.overAllDiscount = d.overAllDiscountTotal;
    e.overAllAlreadyPaid = d.overAllAlreadyPaidTotal;
    e.overAllBalance = d.overAllBalanceTotal;
    e.currentDate = d.collectionDate;
    e.schoolName=this.getAccountNameById(this.loggedUser.accId);
    e.placeName=this.getAccountPlaceById(this.loggedUser.accId);
    for(let i=0;i<d.flist.length;i++){
       let g = new FeeCollectionReceiptDetailsPojo();
      g.termName = this.getTermById(d.flist[i].termId);
      g.standardTermName = this.getStandardTermById(d.flist[i].standardTermId);
      g.paidAmount = d.flist[i].paidAmount;
      g.originalAmount = d.flist[i].originalAmount;
      e.detList.push(g);

    }
    CommonReceiptComponent.FeeCollectionReceipt(e);
    this.reset();
      this.admissionNum = "";
          } );
        }else{

          let d= new FeeCollectionPojo();
          d.flist =[];
          let e= new FeeCollectionReceiptPojo();
          e.detList =[];
          d = response;
           
    e.academicYear = this.getAcademicYrById(d.academicYearId);
    e.admissionNo = d.admissionNo;
    e.className = this.getClassNameById(d.classId);
    e.sectionName = this.getSectionNameById(d.sectionId);
    e.studentName = this.getStudentNameById(d.studentId);
    e.receiptNo = d.receiptNo;
    e.overAllTotal = d.overAllOriginalTotal;
    e.overAllPaid = d.overAllPaidTotal+d.overAllDiscountTotal;
    e.overAllDiscount = d.overAllDiscountTotal;
    e.overAllAlreadyPaid = d.overAllAlreadyPaidTotal;
    e.overAllBalance = d.overAllBalanceTotal;
    e.currentDate = d.collectionDate;
    e.schoolName=this.getAccountNameById(this.loggedUser.accId);
    e.placeName=this.getAccountPlaceById(this.loggedUser.accId);
    for(let i=0;i<d.flist.length;i++){
       let g = new FeeCollectionReceiptDetailsPojo();
      g.termName = this.getTermById(d.flist[i].termId);
      g.standardTermName = this.getStandardTermById(d.flist[i].standardTermId);
      g.paidAmount = d.flist[i].paidAmount;
      g.originalAmount = d.flist[i].originalAmount;
      e.detList.push(g);

    }
    CommonReceiptComponent.FeeCollectionReceipt(e);
     // bootbox.alert( { message: 'Successfully Added', className: 'text-success h5', closeButton: false } );
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
       $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
      // this.isLoading = false;
      this.reset();
      this.admissionNum = "";
    }
  });
    
        }, error => {
      bootbox.alert( { message: ' Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
      $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
     // this.isLoading = false;
  } );
}else{
  alert('Your paid Amount must be Greater than Zero!');
}
}
reset(){
  this.feePojo = new FeeCollectionPojo();
  this.addEntryDate = null;
 this.finalPaidAmount =0;
  this.finalDiscountTotal =0;
  this.overAllAlreadyPaidTotal =0;
  this.overAllOriginalTotal =0; 
   this.finalClassId ="";
  this.finalSectionId ="";
 this.selectedReceiptId =0;
  this.selectedLedgerId =0;
  this.tempDiscounted =0;
  this.tempPushArr =[];
  this.feeCollectionArr = [];
  this.finalStudentId ="";
}
}

export class TempFeeDetArrayPojo{
  feeAllocationId:number;
  standardTermId:number;
  amount:number;
  totalAmount:number;
  gst:number;
  discount:number;
  paidAmount:number;
  balance:number;
  termName:string;
  termId:number;
  discountedAmnt:number;
  originalAmount:number;
  alreadyPaidAmnt:number;
  studentId:number;
 
}
