import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { LogDetailsPojo } from "src/app/pojo/log-details-pojo";
import { Router } from "@angular/router";
import { AcademicSettingService } from '../Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AccountMasterService } from '../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-menubar',
    templateUrl: './menubar.component.html',
    styleUrls: ['./menubar.component.scss'],
    providers:[AcademicSettingService]
} )

export class MenubarComponent implements OnInit {

    loggedUser = new MUserMasterPojo();
    logpojo = new LogDetailsPojo();
    academic:AcademicYearMasterPojo[] =[];
    accountMasterArr:AccountMasterPojo[]=[];
    constructor( private router: Router,private settingService:AcademicSettingService,
        private accountmasterserivce:AccountMasterService) { }

    ngOnInit() {
        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
        this.academicSettingAll();
        this.getAccountMasterAll();
    }
    getAccountMasterAll() {
         this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
            this.accountMasterArr = response;
              }, error => {
            bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
             } );
    }
    academicSettingAll(){
        this.academic = [];
        this.settingService.getAcademicYrAll().subscribe( response => {
            this.academic = response;
              }, error => {
            bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
             } );
       }
       getAcademicYrById( id ) {
        if ( this.academic.find( r => r.academicYearId == id ) ) {
            return this.academic.find( r => r.academicYearId == id ).academicYear;
        } else {
            return id;
        }
    }
    getAccountTitleById( id ) {
        if ( this.accountMasterArr.find( r => r.accId == id ) ) {
            return this.accountMasterArr.find( r => r.accId == id ).title;
        } else {
            return id;
        }
    }
    logout() {
      bootbox.confirm( 'Do you want to Logout?', ( result ) => {
            if ( result ) {
                this.logpojo.logDescription = this.loggedUser.name + " is log out from the management page ";
                this.logpojo.userId = this.loggedUser.userId;
                this.logpojo.name = this.loggedUser.name;
                this.logpojo.logName = "log out";
                sessionStorage.setItem( 'schoolManagementUserDetail', null );
                this.router.navigate( [`/login`] );
            }
        } );
    }
}
