import { Component, OnInit } from '@angular/core';
import { StaffMasterPojo } from "src/app/pojo/staff-master.pojo";
import { StaffMasterService } from "src/app/management/Masters/staff-master/staff-master.service";
import { StaffTypeMasterService } from "src/app/management/Masters/staff-type-master/staff-type-master.servive";
import { StaffTypeMasterPojo } from "src/app/pojo/staff-type-mster.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { MUserMasterPojo, ManagementRolePojo } from "src/app/pojo/management-user-pojo";
import { NgbDateStruct, NgbCalendar, NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { Subject, Observable } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';

import * as moment from 'moment';
import { FtpImagePojo } from 'src/app/pojo/ftp-image.pojo';
import { SubjectMasterService } from '../subject-master/subject-master.service';
import { SubjectMasterPojo } from 'src/app/pojo/subject-master.pojo';

declare var $: any;
declare var bootbox: any;
declare var require: any;

@Component( {
    selector: 'app-staff-master',
    templateUrl: './staff-master.component.html',
    styleUrls: ['./staff-master.component.scss'],
    providers: [StaffMasterService, StaffTypeMasterService]
} )

export class StaffMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    staffTypeMasterArr: StaffTypeMasterPojo[] = [];
    departmentMasterArr: DepartmentMasterPojo[] = [];
    staffMasterArr: StaffMasterPojo[] = [];

    addStaffMaster = new StaffMasterPojo();
    editStaffMaster = new StaffMasterPojo();
    subjectMasterArr:SubjectMasterPojo[]=[];
    staffMaster = new StaffMasterPojo();
    imagePojo: FtpImagePojo = new FtpImagePojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    fileObj: any;
    showSaveBtn: boolean = false;
    imageUploadLoading: boolean = false;
    imageUploadStatus: boolean = false;
    flip: boolean;

    addModalOpen: boolean;
    editModalOpen: boolean;

    // toggle webcam on/off
    public showWebcam = false;
    public mirrorImage = "always";

    // latest snapshot
    public webcamImage: WebcamImage = null;

    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();

    public triggerSnapshot(): void {
        this.trigger.next();
        this.showWebcam = false;
        this.showSaveBtn = true;
    }

    public handleImage( webcamImage: WebcamImage ): void {
        this.webcamImage = webcamImage;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    dummyImage = require( '../../../../assets/image/250x300.png' );

    constructor( private calendar: NgbCalendar,private staffMasterService: StaffMasterService, 
        private staffTypeMasterService: StaffTypeMasterService, 
        private departmentMasterService: DepartmentMasterService, private studentMasterService: StudentMasterService,
        private subjectmasterserivce:SubjectMasterService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
console.log(this.loggedUser);
        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();

        this.getStaffTypeAll();
        this.getDepartmentAll();
        this.getStaffAll();
        this.getSubjectAll();
        
    }
    getSubjectAll() {
        this.isLoading = true;
        this.subjectmasterserivce.getAllSubject(this.loggedUser.accId).subscribe( response => {
            this.subjectMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Subject All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    clickAddBtn() {
        let str = 'assets/image/250x300.png';
            $( '#imageProfile' ).attr( 'src', str );
         this.addModalOpen = true;
        this.editModalOpen = false;
    }

    closeFirstModal() {
        this.showSaveBtn = false;
        $( '#addModal' ).modal( 'hide' );
    }

    openFirstModal() {
        if ( this.editModalOpen == true ) {
            this.editStaffMaster.imagePojo.imagePath = this.imagePojo.imagePath;
          //  $( '#editModal' ).modal( 'show' );
        } else if ( this.addModalOpen == true ) {
            $( '#addModal' ).modal( 'show' );
        }
    }

    resetForm() {
        ( <HTMLFormElement>document.getElementById( 'camModal' ) ).reset();
        $( '#imagePreview' ).removeAttr( 'src' );
        this.webcamImage = null;
        if ( this.flip == true ) {
            $( '#imageProfile' ).removeClass( 'flip' );
            $( '#editImageProfile' ).removeClass( 'flip' );
        }
        $( '#imagePreview' ).attr( 'src', this.dummyImage );
        if ( this.addModalOpen == true ) {
            ( <HTMLFormElement>document.getElementById( 'addStaffForm' ) ).reset();
            $( '#imageProfile' ).removeAttr( 'src' );
            $( '#imageProfile' ).attr( 'src', this.dummyImage );
        } else if ( this.editModalOpen == true ) {
            $( '#editImageProfile' ).removeAttr( 'src' );
            $( '#editImageProfile' ).attr( 'src', this.dummyImage );
        }
    }

    closeFirstEditModal() {
        this.showSaveBtn = false;
      //  $( '#editModal' ).modal( 'hide' );
    }

    LoadCamera() {
        this.showWebcam = true;
        this.webcamImage = null;
        this.showSaveBtn = false;
    }

    readURL() {
        this.webcamImage = null;
        let imageStr = '';
        let input = ( <HTMLInputElement>document.getElementById( 'chooseFile' ) );
        if ( input.files && input.files[0] ) {
            this.fileObj = input.files[0];
            const reader = new FileReader();
            reader.onload = function( e ) {
                imageStr = reader.result as string;
                $( '#imagePreview' ).attr( 'src', imageStr );
            };
            reader.readAsDataURL( input.files[0] );
        }
        this.showSaveBtn = true;
    }
    setImage() {
        let img = $('#imagePreview').attr('src');
        let imgCam = $('#webcamImagePreview').attr('src');
       
        if (imgCam != null) {
            $('#imageProfile').attr('src', imgCam);
           // $('#imageProfile').addClass('flip');
            var file = this.dataURLtoFile(imgCam,'profile.jpg');
            this.fileObj = file;
            let str = 'assets/image/250x300.png';
             $( '#webcamImagePreview' ).attr( 'src', str );
         
        } else {
            $('#imageProfile').attr('src', img);
            $('#imageProfile').removeClass('flip');
            var file = this.dataURLtoFile(img,'profile.jpg');
            this.fileObj = file;
            let str = 'assets/image/250x300.png';
            $( '#imagePreview' ).attr( 'src', str );
          
        }
            if(this.editModalOpen == true){
                this.uploadEditFile(this.fileObj);
            } if(this.addModalOpen == true){
                this.uploadAddFile(this.fileObj);
            }
          
    }

     dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        }
       
    
    uploadAddFile( file: File ) {
        this.imageUploadLoading = true;
        this.studentMasterService.uploadImage(file, 3, 0).subscribe((res) => {
            this.imagePojo = res;
            this.imageUploadLoading = false;
            if (this.imagePojo.imageId != 0) {
                this.imageUploadStatus = true;
                this.addStaffMaster.imagePojo.imageName = this.imagePojo.imageName;
                this.addStaffMaster.imagePojo.imagePath = this.imagePojo.imagePath;
                this.addStaffMaster.imagePojo.imageId = this.imagePojo.imageId;
                this.addStaffMaster.imagePojo.uniqueId = 0;
                $( '#openModal' ).modal( 'hide' );
                this.openFirstModal();
                this.showSaveBtn = false;
            } else {
                bootbox.alert('Failed to uploading a image!');
            }
        }, error => {
            bootbox.alert({ message: 'Add Ftp Image Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            this.imageUploadLoading = false;
        });
       
    }
    uploadEditFile(file: File) {
        //  console.log(JSON.stringify(file));
          this.imageUploadLoading = true;
          //alert(this.editStudentMaster.profile.imagePath);
          if(this.editStaffMaster.imagePojo.imagePath != null && this.editStaffMaster.imagePojo.imagePath != undefined){
          //  alert('if');
            this.studentMasterService.deleteFtpImage(this.editStaffMaster.imagePojo.imageId,3).subscribe( response => {
                this.studentMasterService.uploadImage(file, 3, 0).subscribe((res) => {
                    this.imagePojo = res;
                    this.imageUploadLoading = false;
                    if (this.imagePojo.imageId != 0) {
                        this.imageUploadStatus = true;
                        this.editStaffMaster.imagePojo.imageName = this.imagePojo.imageName;
                        this.editStaffMaster.imagePojo.imagePath = this.imagePojo.imagePath;
                        this.editStaffMaster.imagePojo.imageId = this.imagePojo.imageId;
                        this.editStaffMaster.imagePojo.uniqueId = this.editStaffMaster.staffId;
                        $( '#openModal' ).modal( 'hide' );
                        this.openFirstModal();
                    } else {
                        bootbox.alert('Failed to uploading a image!');
                    }
                }, error => {
                    bootbox.alert({ message: 'Edit Ftp Image Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
                    this.imageUploadLoading = false;
                });   
            }, error => {
                bootbox.alert( 'Delete Service Error! - HTTP Status ' + error.status );
                 } );
          }else{
            //  alert('e');
            this.studentMasterService.uploadImage(file, 3, 0).subscribe((res) => {
                this.imagePojo = res;
                this.imageUploadLoading = false;
                if (this.imagePojo.imageId != 0) {
                    this.imageUploadStatus = true;
                    this.editStaffMaster.imagePojo.imageName = this.imagePojo.imageName;
                    this.editStaffMaster.imagePojo.imagePath = this.imagePojo.imagePath;
                    this.editStaffMaster.imagePojo.imageId = this.imagePojo.imageId;
                    this.editStaffMaster.imagePojo.uniqueId = this.editStaffMaster.staffId;
                    $( '#openModal' ).modal( 'hide' );
                    this.openFirstModal();
                } else {
                    bootbox.alert('Failed to uploading a image!');
                }
            }, error => {
                bootbox.alert({ message: 'Add Ftp Image Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
                this.imageUploadLoading = false;
            });
          }
          
       
          
      }
    getStaffAll() {
        this.staffMasterService.getAllStaff(this.loggedUser.accId).subscribe( run => {
            this.isLoading = false;
            this.staffMasterArr = run;
        }, error => {
            bootbox.alert( { message: 'Get Staff All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getStaffTypeAll() {
        this.staffTypeMasterService.getAllStaffType().subscribe( run => {
               this.staffTypeMasterArr = run;
        }, error => {
            bootbox.alert( { message: 'Get Staff Type All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
              } );
    }

    getDepartmentAll() {
        this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
               this.departmentMasterArr = run;
        }, error => {
            bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
             } );
    }

    getDepartmentNameBydepartmentId( id ) {
        if ( this.departmentMasterArr.find( r => r.deptId == id ) ) {
            return this.departmentMasterArr.find( r => r.deptId == id ).deptName;
        } else {
            return id;
        }
    }

    phoneNumValidationForAdd( num ) {
        const contactNum = /^[6-9]\d{9}$/;
        if ( num.match( contactNum ) || num == '' ) {
        } else {
            ( <HTMLInputElement>document.getElementById( 'addStaffMobile' ) ).value = '';
            alert( 'Please check the mobile number. This number is not valid.' );
            $( '#addStaffMobile' ).focus();
        }
    }
    handleInitError(e){
        
    }
    phoneNumValidationForEdit( num ) {
        const contactNum = /^[6-9]\d{9}$/;
        if ( num.match( contactNum ) || num == '' ) {
        } else {
            ( <HTMLInputElement>document.getElementById( 'editStaffMobile' ) ).value = '';
            alert( 'Please check the mobile number. This number is not valid.' );
            $( '#editStaffMobile' ).focus();
        }
    }

    addStaff() {
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;

        let addStaff = new StaffMasterPojo();
        addStaff = this.addStaffMaster;
        addStaff.dob = moment(( <HTMLInputElement>document.getElementById( 'addStaffDob' ) ).value ).toDate();
        addStaff.doj = moment(( <HTMLInputElement>document.getElementById( 'addStaffDoj' ) ).value ).toDate();
        addStaff.accId=this.loggedUser.accId;
        this.staffMasterService.addStaff( addStaff ).subscribe( response => {
            bootbox.alert( { message: 'Staff Successfully Added', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addStaffForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getStaffAll();
        }, error => {
            bootbox.alert( { message: 'Add Staff Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
      //  this.editStaffMaster = new StaffMasterPojo();
        this.editModalOpen = true;
        this.addModalOpen = false;
        this.editStaffMaster = arg;
      //  alert(this.editStaffMaster.imagePojo.imagePath );
        ( <HTMLFormElement>document.getElementById( 'editStaffDob' ) ).value = arg.dob;
        ( <HTMLFormElement>document.getElementById( 'editStaffDoj' ) ).value = arg.doj;
    }

    editStaff() {
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();

        let editStaff = new StaffMasterPojo();
        this.editStaffMaster.dob = moment( ( <HTMLFormElement>document.getElementById( 'editStaffDob' ) ).value).toDate();
        this.editStaffMaster.doj = moment(( <HTMLFormElement>document.getElementById( 'editStaffDoj' ) ).value).toDate();
        editStaff.accId=this.loggedUser.accId;
        editStaff = this.editStaffMaster;

        this.staffMasterService.updateStaff( editStaff ).subscribe( response => {
            bootbox.alert( { message: 'Staff update has been successful', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getStaffAll();
        }, error => {
            bootbox.alert( { message: 'Update Staff Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();

        let no;
        const i = this.staffMasterArr.indexOf( this.staffMasterArr.find( r => r.staffId == this.id ) );

        this.staffMasterService.deleteStaff( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.staffMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.staffMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getStaffAll();
        }, error => {
            bootbox.alert( { message: 'Delete Staff Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }

}