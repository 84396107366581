import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { AttendanceCertificatePojo, BonafideCertificatePojo, ConductCertificatePojo, CourseCertificatePojo, GenuineCertificatePojo } from 'src/app/pojo/certificate.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class CertificateAllService {

    constructor( private httpclient: HttpClient ) { }

   //Attendance Certificate

    addAttendanceCertificate( arg: AttendanceCertificatePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddAttendanceCertificate';
        return this.httpclient.post( url, arg );
    }
    getAttendanceCertificateBydate(date1,date2): Observable<any> {
        const url = StaticVariable.porturl + 'GetAttendanceCertificateByBetweenDates/'+date1+'/'+date2;
        return this.httpclient.get<AttendanceCertificatePojo[]>( url );
    }
    
    getAttendanceCertificateByAdmissionNum(admissionNo): Observable<any> {
        const url = StaticVariable.porturl + 'GetAttendanceCertificateByAdmissionNo/'+admissionNo;
        return this.httpclient.get<AttendanceCertificatePojo>( url );
    }
    //Bonafide Certificate
    addBonafideCertificate( arg: BonafideCertificatePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddBonafideCertificate';
        return this.httpclient.post( url, arg );
    }
    getBonafideCertificateBydate(date1,date2): Observable<any> {
        const url = StaticVariable.porturl + 'GetBonafideCertificateByBetweenDates/'+date1+'/'+date2;
        return this.httpclient.get<BonafideCertificatePojo[]>( url );
    }
    
    getBonafideCertificateByAdmissionNum(admissionNo): Observable<any> {
        const url = StaticVariable.porturl + 'GetBonafideCertificateByAdmissionNo/'+admissionNo;
        return this.httpclient.get<BonafideCertificatePojo>( url );
    }
    //Conduct Certificate
    addConductCertificate( arg: ConductCertificatePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddConductCertificate';
        return this.httpclient.post( url, arg );
    }
    getConductCertificateBydate(date1,date2): Observable<any> {
        const url = StaticVariable.porturl + 'GetConductCertificateByBetweenDates/'+date1+'/'+date2;
        return this.httpclient.get<ConductCertificatePojo[]>( url );
    }
    
    getConductCertificateByAdmissionNum(admissionNo): Observable<any> {
        const url = StaticVariable.porturl + 'GetConductCertificateByAdmissionNo/'+admissionNo;
        return this.httpclient.get<ConductCertificatePojo>( url );
    }
    //Course Certificate
    addCourseCertificate( arg: CourseCertificatePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddCourseCertificate';
        return this.httpclient.post( url, arg );
    }
    getCourseCertificateBydate(date1,date2): Observable<any> {
        const url = StaticVariable.porturl + 'GetCourseCertificateByBetweenDates/'+date1+'/'+date2;
        return this.httpclient.get<CourseCertificatePojo[]>( url );
    }
    
    getCourseCertificateByAdmissionNum(admissionNo): Observable<any> {
        const url = StaticVariable.porturl + 'GetCourseCertificateByAdmissionNo/'+admissionNo;
        return this.httpclient.get<CourseCertificatePojo>( url );
    }
    //Genuine Certificate
    addGenuineCertificate( arg: GenuineCertificatePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddGenuineCertificate';
        return this.httpclient.post( url, arg );
    }
    getGenuineCertificateBydate(date1,date2): Observable<any> {
        const url = StaticVariable.porturl + 'GetGenuineCertificateByBetweenDates/'+date1+'/'+date2;
        return this.httpclient.get<GenuineCertificatePojo[]>( url );
    }
    
    getGenuineCertificateByAdmissionNum(admissionNo): Observable<any> {
        const url = StaticVariable.porturl + 'GetGenuineCertificateByAdmissionNo/'+admissionNo;
        return this.httpclient.get<GenuineCertificatePojo>( url );
    }
    
}