import { MRolePojo } from "src/app/pojo/role-master.pojo";
import { LoggedUserDetailsPojo } from "src/app/pojo/logged-user-details-pojo";

export class MUserMasterPojo {
    userId: number;
    roleId: number;
    staffId: number;
    name: string;
    userName: string;
    password: string;
    designation: string;
    mobileNo: string;
    address: string;
    userStatus: string;
    roleKeyJson: string;
    loggedUserId: number;
    academicYearId:number;
    accId:number;
    
    loggedUserDetails = new LoggedUserDetailsPojo();
    roleKey = new MRolePojo();
}

export class ManagementRolePojo {
    master = false;
    sms = false;
    admin = false;
    accounts = false;
    register = false;
    Reports = false;
    attendance = false;
    fees = false;
    certificates=false;
    promotionmenu = false;
    studentRegister = false;
    smsCreation = false;
    staffMaster = false;
    staffTypeMaster = false;
    departmentMaster = false;
    sectionMaster = false;
    classMaster = false;
    studentMaster = false;
    roleMaster = false;
    userCreation = false;
    changePassword = false;
    editStudentRegister = false;
    smsMaster = false;
    editSmsMaster = false;
    smsTemplateMaster = false;
    smsHistory = false;
    journalentry = false;
    ledgercreation = false;
    accountmaster = false;
    payment = false;
    receiptentry = false;
    receipthead = false;
    subjectmaster = false;
    receiptDetails = false;
    academicSetting = false;
    quickAdmission = false;
    genderwise = false;
    classwise = false;
    communitywise = false;
    physicallydisabled = false;
    strength = false;
    ptrintstudentmaster = false;
    communitymaster = false;
    castemaster = false;
    promotion = false;
    sectionallocation = false;
    feecollection = false;
    feeallocation = false;
    term = false;
    standardstructure = false;
    entryJournal = false;
    entryPayment = false;
    entryReceipt = false;
    ledgerStatement = false;
    examMaster = false;
    hoursMaster = false;
    holidayList = false;
    arrearsReport = false;
    attendanceEntry = false;
    daywiseAttendanceEntry = false;
    notesMaster = false;
    standardTermWiseReport = false;
    discountMaster = false;
    discountFixing = false;
    discountTypeMaster = false;
    areaMaster = false;
    examSchedule = false;
    studentMarkList = false;
    studExamReport=false;
    busFeeAllocation=false;
    tcGeneration=false;
    tcGenerationReport=false;
    examMarkSheet=false;
    refund=false;
    overallAccounts=false;
    feeCollectionDetails=false;
    refundReport=false;
    smsSettings=false;
    smsContentSettings=false;
    absentList=false;
    feeAllocationSms=false;
    busStudentsReport=false;
    attendanceGen=false;
    bonafideGen=false;
    conductGen=false;
    courseGen=false;
    genuineGen=false;
    attendanceReport=false;
    bonafideReport=false;
    conductReport=false;
    courseReport=false;
    genuineReport=false;
    certificateReprint=false;
    resultPostingSms=false;
    cashBookReport=false;
    incomeExpenditureReport=false;
    trialbalance = false;
}
