import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenubarComponent } from "src/app/management/menubar/menubar.component";
import { ClassMasterComponent } from "src/app/management/Masters/class-master/class-master.component";
import { StudentRegisterComponent } from "src/app/management/student-register/student-register.component";
import { RoleMasterComponent } from "src/app/management/Admin/role-master/role-master.component";
import { SmsCreationComponent } from "src/app/management/SMS/sms-creation/sms-creation.component";
import { DepartmentMasterComponent } from "src/app/management/Masters/department-master/department-master.component";
import { SectionMasterComponent } from "src/app/management/Masters/section-master/section-master.component";
import { StaffMasterComponent } from "src/app/management/Masters/staff-master/staff-master.component";
import { StaffTypeMasterComponent } from "src/app/management/Masters/staff-type-master/staff-type-master.component";
import { StudentMasterComponent } from "src/app/management/Masters/student-master/student-master.component";
import { ChangePasswordComponent } from "src/app/management/Admin/change-password/change-password.component";
import { UserCreationComponent } from "src/app/management/Admin/user-creation/user-creation.component";
import { CurriculumMasterComponent } from "src/app/management/Masters/curriculum-master/curriculum-master.component";
import { LoginComponent } from "src/app/management/login/login.component";
import { EditStudentMasterComponent } from "src/app/management/Masters/student-master/edit-student-master/edit-student-master.component";
import { ManagementAuthenticationGuard } from "src/app/management/Authentication/management-authentication-guard";
import { SmsMasterComponent } from "src/app/management/SMS/sms-master/sms-master.component";
import { EditSmsMasterComponent } from "src/app/management/SMS/edit-sms-master/edit-sms-master.component";
import { SmsTemplateComponent } from "src/app/management/SMS/sms-template/sms-template.component";
import { SmsHistoryComponent } from "src/app/management/SMS/sms-history/sms-history.component";
import { ReceiptHeadComponent } from "src/app/management/Accounts/receipt-head/receipt-head.component";
import { ReceiptEntryComponent } from "src/app/management/Accounts/receipt-entry/receipt-entry.component";
import { JournalEntryComponent } from "src/app/management/Accounts/journal-entry/journal-entry.component";
import { LedgercreationComponent } from "src/app/management/Masters/ledgercreation/ledgercreation.component";
import { AccountMasterComponent } from "src/app/management/Admin/account-master/account-master.component";
import { PaymentComponent } from "src/app/management/Accounts/payment/payment.component";
import { SubjectMasterComponent } from "src/app/management/Masters/subject-master/subject-master.component";
import { ReceiptDetailsComponent } from './management/Accounts/receipt-details/receipt-details.component';
import { AcademicSettingComponent } from './management/Admin/academic-setting/academic-setting.component';
import { QuickAdmissionComponent } from './management/quick-admission/quick-admission.component';
import { GenderWiseComponent } from './management/Reports/gender-wise/gender-wise.component';
import { ClassWiseComponent } from './management/Reports/class-wise/class-wise.component';
import { CommunityWiseComponent } from './management/Reports/community-wise/community-wise.component';
import { PhysicallyDisabledComponent } from './management/Reports/physically-disabled/physically-disabled.component';
import { StrengthComponent } from './management/Reports/strength/strength.component';
import { PrintStudentMasterComponent } from './management/Masters/student-master/print-student-master/print-student-master.component';
import { CommunityMasterComponent } from './management/Masters/community-master/community-master.component';
import { CasteMasterComponent } from './management/Masters/caste-master/caste-master.component';
import { PromotionComponent } from './management/Promotion/promotion/promotion.component';
import { SectionAllocationComponent } from './management/Promotion/section-allocation/section-allocation.component';
import { FeeCollectionComponent } from './management/Fees/fee-collection/fee-collection.component';
import { FeeAllocationComponent } from './management/Fees/fee-allocation/fee-allocation.component';
import { TermComponent } from './management/Masters/term/term.component';
import { StandardStructureComponent } from './management/Masters/standard-structure/standard-structure.component';
import { JournalComponent } from './management/Entry/journal-entry/journal-entry.component';
import { PaymentEntryComponent } from './management/Entry/payment-entry/payment-entry.component';
import { ReceiptComponent } from './management/Entry/receipt-entry/receipt-entry.component';
import { LedgerStatementComponent } from './management/Reports/ledger-statement/ledger-statement.component';
import { ExamMasterComponent } from './management/Masters/exam-master/exam-master.component';
import { HolidayListComponent } from './management/Masters/holiday-list/holiday-list.component';
import { HoursMasterComponent } from './management/Masters/hours-master/hours-master.component';
import { ArrearsReportComponent } from './management/Reports/arrears-report/arrears-report.component';
import { AttendanceEntryComponent } from './management/Attendance/attendance-entry/attendance-entry.component';
import { DaywiseAttendanceEntryComponent } from './management/Reports/daywise-attendance-entry/daywise-attendance-entry.component';
import { NotesMasterComponent } from './management/Masters/notes-master/notes-master.component';
import { StandardTermWiseComponent } from './management/Reports/standard-term-wise/standard-term-wise.component';
import { DiscountMasterComponent } from './management/Masters/discount-master/discount-master.component';
import { DiscountFixingComponent } from './management/Fees/discount-fixing/discount-fixing.component';
import { DicountTypeMasterComponent } from './management/Masters/dicount-type-master/dicount-type-master.component';
import { AreaMasterComponent } from './management/Masters/area-master/area-master.component';
import { ExamScheduleComponent } from './management/Masters/exam-schedule/exam-schedule.component';
import { StudentMarkListComponent } from './management/Reports/student-mark-list/student-mark-list.component';
import { StudExamReportComponent } from './management/Reports/stud-exam-report/stud-exam-report.component';
import { CreateSmsTemplateComponent } from './management/SMS/create-sms-template/create-sms-template.component';
import { BusFeeAllocationComponent } from './management/Fees/bus-fee-allocation/bus-fee-allocation.component';
import { TcGenerationComponent } from './management/Reports/tc-generation/tc-generation.component';
import { TcGenerationReportComponent } from './management/Reports/tc-generation-report/tc-generation-report.component';
import { ExamMarkSheetComponent } from './management/Reports/exam-mark-sheet/exam-mark-sheet.component';
import { RefundComponent } from './management/Fees/refund/refund.component';
import { RefundFormComponent } from './management/Fees/refund/refund-form/refund-form.component';
import { OverallAccountsReportComponent } from './management/Accounts/overall-accounts-report/overall-accounts-report.component';
import { FeecollectionDetailsReportComponent } from './management/Fees/feecollection-details-report/feecollection-details-report.component';
import { ReundReportComponent } from './management/Fees/reund-report/reund-report.component';
import { SmsSettingsComponent } from './management/Admin/sms-settings/sms-settings.component';
import { SmsTypeContentSettingsComponent } from './management/SMS/sms-type-content-settings/sms-type-content-settings.component';
import { AbsentListComponent } from './management/SMS/absent-list/absent-list.component';
import { SetFeeAllocationSmsComponent } from './management/SMS/set-fee-allocation-sms/set-fee-allocation-sms.component';
import { BusStudentsReportComponent } from './management/Fees/bus-students-report/bus-students-report.component';
import { TReprintComponent } from './management/Reports/t-reprint/t-reprint.component';
import { BonafideGenerationComponent } from './management/Certificates/bonafide-generation/bonafide-generation.component';
import { AttendanceGenerationComponent } from './management/Certificates/attendance-generation/attendance-generation.component';
import { ConductGenerationComponent } from './management/Certificates/conduct-generation/conduct-generation.component';
import { CourseGenerationComponent } from './management/Certificates/course-generation/course-generation.component';
import { GenuineGenerationComponent } from './management/Certificates/genuine-generation/genuine-generation.component';
import { ConductReportComponent } from './management/Certificates/conduct-report/conduct-report.component';
import { BonafideReportComponent } from './management/Certificates/bonafide-report/bonafide-report.component';
import { AttendanceReportComponent } from './management/Certificates/attendance-report/attendance-report.component';
import { CourseReportComponent } from './management/Certificates/course-report/course-report.component';
import { GenuineReportComponent } from './management/Certificates/genuine-report/genuine-report.component';
import { CertificateReprintComponent } from './management/Certificates/certificate-reprint/certificate-reprint.component';
import { CashBookReportComponent } from './management/Accounts/cash-book-report/cash-book-report.component';
import { ResultPostingSmsComponent } from './management/SMS/result-posting-sms/result-posting-sms.component';
import { IncomeExpenditureReportComponent } from './management/Accounts/income-expenditure-report/income-expenditure-report.component';
import { TrialBalanceComponent } from './management/Accounts/trial-balance/trial-balance.component';

const routes: Routes = [
    {
        path: '', component: LoginComponent
    },
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'console', component: MenubarComponent,
        children: [
            { path: '', component: ChangePasswordComponent },
            { path: '', component: StudentRegisterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'studentRegister' } },
            { path: 'studentRegister', component: StudentRegisterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'studentRegister' } },
            { path: 'smsCreation', component: SmsCreationComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'smsCreation' } },
            { path: 'smsTemplateCreation', component: CreateSmsTemplateComponent },
            { path: 'templateSmsCreation/:smsId', component: SmsCreationComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'smsCreation' } },
            { path: 'staffMaster', component: StaffMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'staffMaster' } },
            { path: 'staffTypeMaster', component: StaffTypeMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'staffTypeMaster' } },
            { path: 'departmentMaster', component: DepartmentMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'departmentMaster' } },
            { path: 'sectionMaster', component: SectionMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'sectionMaster' } },
            { path: 'classMaster', component: ClassMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'classMaster' } },
            { path: 'studentMaster', component: StudentMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'studentMaster' } },
            { path: 'roleMaster', component: RoleMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'roleMaster' } },
            { path: 'userCreation', component: UserCreationComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'userCreation' } },
            { path: 'editStudentRegister/:studentId', component: EditStudentMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'editStudentRegister' } },
            { path: 'smsMaster', component: SmsMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'smsMaster' } },
            { path: 'editSmsMaster/:smsId', component: EditSmsMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'editSmsMaster' } },
            { path: 'smsTemplateMaster', component: SmsTemplateComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'smsTemplateMaster' } },
            { path: 'smsHistory', component: SmsHistoryComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'smsTemplateMaster' } },
            { path: 'journalentry', component: JournalEntryComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'journalentry' } },
            { path: 'ledgercreation', component: LedgercreationComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'ledgercreation' } },
            { path: 'accountmaster', component: AccountMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'accountmaster' } },
            { path: 'payment', component: PaymentComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'payment' } },
            { path: 'receiptentry', component:ReceiptEntryComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'receiptentry' } },
            { path: 'receipthead', component: ReceiptHeadComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'receipthead' } },
            { path: 'subjectmaster', component: SubjectMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'subjectmaster' } },
            { path: 'receiptDetails', component: ReceiptDetailsComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'receiptDetails' } },
            { path: 'academicSetting', component: AcademicSettingComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'academicSetting' } },
            { path: 'quickAdmission', component: QuickAdmissionComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'quickAdmission' } },
            { path: 'genderwise', component: GenderWiseComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'genderwise' } },
            { path: 'classwise', component: ClassWiseComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'classwise' } },
            { path: 'communitywise', component: CommunityWiseComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'communitywise' } },
            { path: 'physicallydisabled', component: PhysicallyDisabledComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'physicallydisabled' } },
            { path: 'strength', component: StrengthComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'strength' } },
            { path: 'ptrintstudentmaster', component: PrintStudentMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'ptrintstudentmaster' } },
            { path: 'communitymaster', component: CommunityMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'communitymaster' } },
            { path: 'castemaster', component: CasteMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'castemaster' } },
            { path: 'promotion', component: PromotionComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'promotion' } },
            { path: 'sectionallocation', component: SectionAllocationComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'sectionallocation' } },
            { path: 'feecollection', component: FeeCollectionComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'feecollection' } },
            { path: 'feeallocation', component: FeeAllocationComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'feeallocation' } },
            { path: 'term', component: TermComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'term' } },
            { path: 'standardstructure', component: StandardStructureComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'standardstructure' } },
            { path: 'entryJournal', component: JournalComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'entryJournal' } },
            { path: 'entryPayment', component: PaymentEntryComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'entryPayment' } },
            { path: 'entryReceipt', component: ReceiptComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'entryReceipt' } },
            { path: 'ledgerStatement', component: LedgerStatementComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'ledgerStatement' } },
            { path: 'examMaster', component: ExamMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'examMaster' } },
            { path: 'holidayList', component: HolidayListComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'holidayList' } },
            { path: 'hoursMaster', component: HoursMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'hoursMaster' } },
            { path: 'arrearsReport', component: ArrearsReportComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'arrearsReport' } },
            { path: 'attendanceEntry', component: AttendanceEntryComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'attendanceEntry' } },
            { path: 'daywiseAttendanceEntry', component: DaywiseAttendanceEntryComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'daywiseAttendanceEntry' } },
            { path: 'notesMaster', component: NotesMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'notesMaster' } },
            { path: 'standardTermWiseReport', component: StandardTermWiseComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'standardTermWiseReport' } },
            { path: 'discountMaster', component: DiscountMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'discountMaster' } },
            { path: 'discountFixing', component: DiscountFixingComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'discountFixing' } },
            { path: 'discountTypeMaster', component: DicountTypeMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'discountTypeMaster' } },
            { path: 'areaMaster', component: AreaMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'areaMaster' } },
            { path: 'examSchedule', component: ExamScheduleComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'examSchedule' } },
            { path: 'studentMarkList', component: StudentMarkListComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'studentMarkList' } },
            { path: 'studExamReport', component: StudExamReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'studExamReport' } },
            { path: 'busFeeAllocation', component: BusFeeAllocationComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'busFeeAllocation' } },
            { path: 'tcGeneration', component: TcGenerationComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'tcGeneration' } },
            { path: 'tcGenerationReport', component: TcGenerationReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'tcGenerationReport' } },
            { path: 'examMarkSheet', component: ExamMarkSheetComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'examMarkSheet' } },
            { path: 'refund', component: RefundComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'refund' } },
            { path: 'refundForm', component: RefundFormComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'refund' } },
            { path: 'overallAccounts', component: OverallAccountsReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'overallAccounts' } },
            { path: 'feeCollectionDetails', component: FeecollectionDetailsReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'feeCollectionDetails' } },
            { path: 'refundReport', component: ReundReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'refundReport' } },
            { path: 'smsSettings', component: SmsSettingsComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'smsSettings' } },
            { path: 'smsContentSettings', component: SmsTypeContentSettingsComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'smsContentSettings' } },
            { path: 'absentList', component: AbsentListComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'absentList' } },
            { path: 'feeAllocationSms', component: SetFeeAllocationSmsComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'feeAllocationSms' } },
            { path: 'busStudentsReport', component: BusStudentsReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'busStudentsReport' } },
            { path: 'tcReprint', component: TReprintComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'tcGeneration' } },
            { path: 'attendanceGen', component: AttendanceGenerationComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'attendanceGen' } },
            { path: 'bonafideGen', component: BonafideGenerationComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'bonafideGen' } },
            { path: 'conductGen', component: ConductGenerationComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'conductGen' } },
            { path: 'courseGen', component: CourseGenerationComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'courseGen' } },
            { path: 'genuineGen', component: GenuineGenerationComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'genuineGen' } },
            { path: 'attendanceReport', component: AttendanceReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'attendanceReport' } },
            { path: 'bonafideReport', component: BonafideReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'bonafideReport' } },
            { path: 'conductReport', component: ConductReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'conductReport' } },
            { path: 'courseReport', component: CourseReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'courseReport' } },
            { path: 'genuineReport', component: GenuineReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'genuineReport' } },
            { path: 'certificateReprint', component: CertificateReprintComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'certificateReprint' } },
            { path: 'cashBookReport', component: CashBookReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'cashBookReport' } },
            { path: 'resultPostingSms', component: ResultPostingSmsComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'resultPostingSms' } },
            { path: 'incomeExpenditureReport', component: IncomeExpenditureReportComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'incomeExpenditureReport' } },
            { path: 'trialbalance', component: TrialBalanceComponent , canActivate: [ManagementAuthenticationGuard], data: { roles: 'trialbalance' } },
            
        ] 
       } ];   

@NgModule( {
    imports: [
        RouterModule.forRoot( routes, { useHash: true } ),
    ],
    exports: [
        RouterModule
    ],
    providers: [
        ManagementAuthenticationGuard  
    ]
} )

export class AppRoutingModule { }
